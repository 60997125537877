import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import BCover from '../static/bilaal-cover.png';
import KCover from '../static/kirsty-cover.png';
import SCover from '../static/shibon-cover.png';
import Bilaal from '../static/EWY_Bilaal Ashfak_CASE_STUDY.mp4';
import Kirsty from '../static/EWY_Kirsty Wilcock_CASE_STUDY.mp4';
import Shibon from '../static/EWY_Shibon Armstrong_CASE_STUDY.mp4';
import PlayButton from '../static/play--button.svg';

import Jad from '../static/EWY_Jad.mp4';
import Rebecca from '../static/EWY_Rebecca.mp4';
import Shar from '../static/EWY_Shar Simpson.mp4';
import JCover from '../static/jad-cover.png';
import RCover from '../static/rebecca-cover.png';
import ShCover from '../static/shar-cover.png';

const EwyPage = (props) => {
    const { data, errors } = props;

    const bilaalVideo = useRef();
    const playButtonB = useRef();

    const [isPlayingB, setIsPlayingB] = useState(false);

    const handlePlayB = e => {
        if(!isPlayingB) {
            setIsPlayingB(true);
            bilaalVideo.current.play();
        } else {
            setIsPlayingB(false);
            bilaalVideo.current.pause();
        }
    }

    const kirstyVideo = useRef();
    const playButtonK = useRef();

    const [isPlayingK, setIsPlayingK] = useState(false);

    const handlePlayK = e => {
        if(!isPlayingK) {
            setIsPlayingK(true);
            kirstyVideo.current.play();
        } else {
            setIsPlayingK(false);
            kirstyVideo.current.pause();
        }
    }

    const shibonVideo = useRef();
    const playButtonS = useRef();

    const [isPlayingS, setIsPlayingS] = useState(false);

    const handlePlayS = e => {
        if(!isPlayingS) {
            setIsPlayingS(true);
            shibonVideo.current.play();
        } else {
            setIsPlayingS(false);
            shibonVideo.current.pause();
        }
    }

    const jadVideo = useRef();
    const playButtonJ = useRef();

    const [isPlayingJ, setIsPlayingJ] = useState(false);

    const handlePlayJ = e => {
        if(!isPlayingJ) {
            setIsPlayingJ(true);
            jadVideo.current.play();
        } else {
            setIsPlayingJ(false);
            jadVideo.current.pause();
        }
    }

    const rebVideo = useRef();
    const playButtonR = useRef();

    const [isPlayingR, setIsPlayingR] = useState(false);

    const handlePlayR = e => {
        if(!isPlayingR) {
            setIsPlayingR(true);
            rebVideo.current.play();
        } else {
            setIsPlayingR(false);
            rebVideo.current.pause();
        }
    }

    const sharVideo = useRef();
    const playButtonSh = useRef();

    const [isPlayingSh, setIsPlayingSh] = useState(false);

    const handlePlaySh = e => {
        if(!isPlayingSh) {
            setIsPlayingSh(true);
            sharVideo.current.play();
        } else {
            setIsPlayingSh(false);
            sharVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
                
                <div className='ewy-landing'>
                    <div className='aviva--page-banner-image showreel' id="bilaal">
                        <div className='video--wrapper'>
                            {isPlayingB ? null :
                                <div className='video--overlay' onClick={handlePlayB}>
                                    <img src={PlayButton} ref={playButtonB} />
                                </div>
                            }
                            <video
                                ref={bilaalVideo}
                                className="hero__media"
                                controls="false"
                                poster={BCover}
                            >
                                <source src={Bilaal} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="kirsty">
                        <div className='video--wrapper'>
                            {isPlayingK ? null :
                                <div className='video--overlay' onClick={handlePlayK}>
                                    <img src={PlayButton} ref={playButtonK} />
                                </div>
                            }
                            <video
                                ref={kirstyVideo}
                                className="hero__media"
                                controls="false"
                                poster={KCover}
                            >
                                <source src={Kirsty} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="shibon">
                        <div className='video--wrapper'>
                            {isPlayingS ? null :
                                <div className='video--overlay' onClick={handlePlayS}>
                                    <img src={PlayButton} ref={playButtonS} />
                                </div>
                            }
                            <video
                                ref={shibonVideo}
                                className="hero__media"
                                controls="false"
                                poster={SCover}
                            >
                                <source src={Shibon} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="jad">
                        <div className='video--wrapper'>
                            {isPlayingJ ? null :
                                <div className='video--overlay' onClick={handlePlayJ}>
                                    <img src={PlayButton} ref={playButtonJ} />
                                </div>
                            }
                            <video
                                ref={jadVideo}
                                className="hero__media"
                                controls="false"
                                poster={JCover}
                            >
                                <source src={Jad} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="reb">
                        <div className='video--wrapper'>
                            {isPlayingR ? null :
                                <div className='video--overlay' onClick={handlePlayR}>
                                    <img src={PlayButton} ref={playButtonR} />
                                </div>
                            }
                            <video
                                ref={rebVideo}
                                className="hero__media"
                                controls="false"
                                poster={RCover}
                            >
                                <source src={Rebecca} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="shar">
                        <div className='video--wrapper'>
                            {isPlayingSh ? null :
                                <div className='video--overlay' onClick={handlePlaySh}>
                                    <img src={PlayButton} ref={playButtonSh} />
                                </div>
                            }
                            <video
                                ref={sharVideo}
                                className="hero__media"
                                controls="false"
                                poster={ShCover}
                            >
                                <source src={Shar} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>

            </div>
        </Container>
        </Layout>
    </>
  )
};

EwyPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default EwyPage;
